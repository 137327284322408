// react libraries
import { Page, Text, View, Image } from '@react-pdf/renderer';

// services
import { styles } from "../../../../../services";

const OrdensDeServicoFilesCertificadoFiltros = ( { data, serviceId } : any ) => {

    const dueDate = data.services.filter((v:any) => v.id == serviceId)[0]?.due_date;

    // Obter a data atual
    const dataAtual = new Date();

    // Obter dia, mês e ano
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();

    // Formatar a data no formato d/m/Y
    const dataFormatada = `${dia}/${mes}/${ano}`;

    return (
        <>
            <Page size="A4" style={styles.page}>
                {/* HEADER */}
                <View style={{...styles.section, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, paddingBottom: 0}}>
                    <Image src="/assets/img/logo.png" style={styles.image} />
                </View>
                <View style={styles.section}>
                    <Text style={{...styles.title, marginBottom: 20}}>Certificado  DE SUBSTITUIÇÃO DE FILTROS</Text>
                    <View style={{...styles.table}}>
                        <View style={styles.row}>
                            <Text style={{...styles.col15, ...styles.cpText, ...styles.borderRight}}>Ordem de serviço: #{data.id}</Text>
                            <Text style={{...styles.col5, ...styles.cpText}}>Data emissão: {dataFormatada}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.tableTh, fontWeight: 700}}>Informações do cliente</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Nome/Razão Social:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_name}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Endereço:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_address}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>{String(data.client_cpf_cnpj).length > 14 ? 'CNPJ' : 'CPF'}</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cpf_cnpj}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>CEP:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cep}</Text>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.flText}>
                            A NLG Solutions, inscrita no CNPJ 24.534.437/0001-73, certifica que houve substituição 
                            dos refis (filtros) para purificação de água de consumo no local citado.
                        </Text>
                        <Text style={styles.flText}>
                            Ao total foram substituídos {data.qty} filtros e estão identificados com adesivo da empresa no
                            local da troca, constando a data de realização, data de substituição e técnico que executou.
                        </Text>
                        <Text style={styles.flText}>
                            Data execução: {data.started_execution_at_format_day} {'\n'}
                            { dueDate !== data.started_execution_at_format_day ? `Data vencimento: ${dueDate} \n` : null }
                            Técnico responsável: {data.finished_name}
                        </Text>
                    </View>
                    <View style={{...styles.cpSignature}}>
                        <Text style={{...styles.textCenter, fontSize: 10}}>NLG SOLUTIONS</Text>
                        <Text style={{...styles.textCenter, fontSize: 10}}>CNPJ: 24.534.437/0001-73</Text>
                    </View>
                </View>
            </Page>
        </>
    )

}

export default OrdensDeServicoFilesCertificadoFiltros;