// react libraries
import { Page, Text, View, Image } from '@react-pdf/renderer';

// services
import { styles } from "../../../../../services";

const OrdensDeServicoFilesCertificadoPlanilhaMonitoramento = ( { data, serviceId, traps, values } : any ) => {

    // Obter a data atual
    const dataAtual = new Date();

    // Obter dia, mês e ano
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();

    // Formatar a data no formato d/m/Y
    const dataFormatada = `${dia}/${mes}/${ano}`;

    return (
        <>
            <Page size="A4" style={styles.page}>
                {/* HEADER */}
                <View style={{...styles.section, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, paddingBottom: 0}}>
                    <Image src="/assets/img/logo.png" style={styles.image} />
                </View>
                <View style={styles.section}>
                    <Text style={{...styles.title, marginBottom: 20}}>Planilha de Monitoramento</Text>
                    <View style={{...styles.table}}>
                        <View style={styles.row}>
                            <Text style={{...styles.col15, ...styles.cpText, ...styles.borderRight}}>Ordem de serviço: #{data.id}</Text>
                            <Text style={{...styles.col5, ...styles.cpText}}>Data emissão: {dataFormatada}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.tableTh, fontWeight: 700}}>Informações do cliente</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Nome/Razão Social:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_name}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Endereço:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_address}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>{String(data.client_cpf_cnpj).length > 14 ? 'CNPJ' : 'CPF'}</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cpf_cnpj}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>CEP:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cep}</Text>
                        </View>
                    </View>
                    { traps.map((trap:any, trapIndex:number) => {
                        var linha = 1;
                        return (
                            <View style={{...styles.table, marginTop: 10}} key={trapIndex}>
                                <View style={styles.row}>
                                    <Text style={{...styles.col20, ...styles.tableTh}}>{trap.name}</Text>
                                </View>
                                <View style={{...styles.row, height: 200}}>
                                    <View style={{...styles.borderRight, ...styles.headerTable, width: '15%'}}>
                                        <Text style={{...styles.tableThRotacionado}}>Armadilhas</Text>
                                    </View>
                                    <View style={{...styles.borderRight, ...styles.headerTable, width: '35%'}}>
                                        <Text style={{...styles.tableThComum}}>Setor</Text>
                                    </View>
                                    { trap.situations.map((situation:any, situationIndex:number) => (
                                        <View key={situationIndex} style={{...styles.borderRight, ...styles.headerTable, width:  `${50/Number(trap.situations.length)}%`}}>
                                            <Text style={{...styles.tableThRotacionado}}>{situation.name}</Text>
                                        </View>
                                    )) }
                                </View>
                                { trap.sectors.map((sector:any, sectorIndex: number) => (
                                    <>
                                        <View key={sectorIndex} style={{...styles.row}}>
                                            <View style={{...styles.borderRight, ...styles.headerTable, width: '15%'}}>
                                                <Text style={{...styles.tableThComum, paddingVertical: 4}}>{trap.abbreviation} - {linha++}</Text>
                                            </View>
                                            <View style={{...styles.borderRight, ...styles.headerTable, width: '35%'}}>
                                                <Text style={{...styles.tableThComum, paddingVertical: 4}}>{sector.name}</Text>
                                            </View>
                                            { trap.situations.map((situation:any, situationIndex:number) => (
                                                <View key={situationIndex} style={{...styles.borderRight, ...styles.headerTable, width:  `${50/Number(trap.situations.length)}%`}}>
                                                    <Text style={{...styles.tableThComum, paddingVertical: 4}}>{values.includes(`${situation.id}-${sector.id}-1`) ? 'X' : ''}</Text>
                                                </View>
                                            )) }
                                        </View>
                                        { sector.qty > 1 ? new Array(sector.qty-1).fill('-').map((qty,qtyIndex) => (
                                            <View key={sectorIndex+'-'+qtyIndex} style={{...styles.row}}>
                                                <View style={{...styles.borderRight, ...styles.headerTable, width: '15%'}}>
                                                    <Text style={{...styles.tableThComum, paddingVertical: 4}}>{trap.abbreviation} - {linha++}</Text>
                                                </View>
                                                <View style={{...styles.borderRight, ...styles.headerTable, width: '35%'}}>
                                                    <Text style={{...styles.tableThComum, paddingVertical: 4}}>{sector.name}</Text>
                                                </View>
                                                { trap.situations.map((situation:any, situationIndex:number) => (
                                                    <View key={situationIndex} style={{...styles.borderRight, ...styles.headerTable, width:  `${50/Number(trap.situations.length)}%`}}>
                                                        <Text style={{...styles.tableThComum, paddingVertical: 4}}>{values.includes(`${situation.id}-${sector.id}-${qtyIndex+2}`) ? 'X' : ''}</Text>
                                                    </View>
                                                )) }
                                            </View>
                                        )) : null }
                                    </>
                                ))}
                            </View>
                        )
                    })}
                    <View style={{...styles.table}}>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.tableTh}}>Observações</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpObs2}}>{data.obs_planilha}</Text>
                        </View>
                    </View>
                    <View style={{...styles.cpSignature}}>
                        <Text style={{...styles.textCenter, fontSize: 10}}>NLG SOLUTIONS</Text>
                        <Text style={{...styles.textCenter, fontSize: 10}}>CNPJ: 24.534.437/0001-73</Text>
                    </View>
                </View>
            </Page>
        </>
    )

}

export default OrdensDeServicoFilesCertificadoPlanilhaMonitoramento;