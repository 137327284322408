// react libraries
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Select, Typography } from "antd";
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

// services
import { CLN, POST_API, POST_CATCH, PageDefaultProps, getToken, styles, verifyConfig } from "../../../../services";

// components
import LoadItemComponent from "../../../../components/LoadItemComponent";
import PageDefaultComponent from "../../../../components/PageDefaultComponent";
import CardAdminComponent from "../../../../components/CardAdminComponent";
import { TableReturnButton } from "../../../../components/TableComponent/buttons";
import OrdensDeServicoFilesCertificadoControlePragas from "./certificados/controlePragas";
import OrdensDeServicoFilesCertificadoFiltros from "./certificados/Filtros";
import OrdensDeServicoFilesCertificadoHigienizacao from "./certificados/Higienizacao";
import OrdensDeServicoFilesCertificadoPlanilhaMonitoramento from "./certificados/planilhaMonitoramento";
import LoadComponent from "../../../../components/LoadComponent";
import OrdensDeServicoFilesCertificadoComprovanteExecucao from "./certificados/comprovanteExecucao";


const OrdensDeServicoFilesCertificado = ( { type, path, permission } : PageDefaultProps ) => {

    // params 
    const { ID, SERVICE, CERTIFICATE } = useParams()

    // state
    const [ load, setLoad ] = useState(true)

    // form
    const [ cert, setCert ] = useState<any>(null)
    const [ data, setData ] = useState<any>(null)

    // valid new or edit
    useEffect(() => {
        onReload()
    }, [type, path, ID]);

    const onReload = () => {
        if (type === 'add') { 
            setLoad(false)
        } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    setData(res.data)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => {setLoad(false)})
        }
    }

    useEffect(() => {
        POST_API(`/certificates/search.php`, { token: getToken(), filter: JSON.stringify({ id: CERTIFICATE }), type }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setCert(res.data)
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH)
    }, []);

    return (
        <PageDefaultComponent title="Gerenciamento de Ordens de Serviço" items={[
            { title: `Certificado - ${cert?.name}` },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                        <Row gutter={[16,16]}>
                            <Col md={24} xs={24}>
                                <CardAdminComponent title={`Certificado`} subtitle={`${cert?.name}`} options={
                                    <Row justify={'end'} gutter={[8,8]}>
                                        <Col><Link to={`/sistema/ordensdeservico/${ID}/documentos`}><Button size="small" shape="round" type="default" className="btn-default">Voltar</Button></Link></Col>
                                    </Row>
                                }>
                                    { CERTIFICATE === '4' ? (
                                        <PlanilhaMonitoramento reload={onReload} serviceId={SERVICE} budgetId={ID} certificateId={CERTIFICATE} data={data} /> 
                                    ) : CERTIFICATE === '5' ? (
                                        <ComprovanteExecucao reload={onReload} serviceId={SERVICE} budgetId={ID} certificateId={CERTIFICATE} data={data} /> 
                                    ) : (
                                        <PDFViewer style={{width: '100%', height: 700}}>
                                            <Document>
                                                { CERTIFICATE === '1' ? <OrdensDeServicoFilesCertificadoFiltros serviceId={SERVICE} data={data} /> : null }
                                                { CERTIFICATE === '2' ? <OrdensDeServicoFilesCertificadoHigienizacao serviceId={SERVICE} data={data} /> : null }
                                                { CERTIFICATE === '3' ? <OrdensDeServicoFilesCertificadoControlePragas serviceId={SERVICE} data={data} /> : null }
                                            </Document>
                                        </PDFViewer>
                                    ) }
                                </CardAdminComponent>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

const ComprovanteExecucao = ({serviceId, budgetId, certificateId, data, reload} : any) => {

    const [form] = Form.useForm()

    const service = data.services.filter((v:any) => v.id == serviceId)[0];
    const certificate = service.certificates.filter((v:any) => v.certificate_id == certificateId)[0];
    const certificateService = service.need_certificate_info.filter((v:any) => v.certificate_id == certificate.id)[0];

    const [ load, setLoad ] = useState<boolean>(true)
    const [ loadButton, setLoadButton ] = useState<boolean>(false)

    const [ dados, setDados ] = useState<any>()
    const [ activity, setActivity ] = useState<any[]>([0])
    const [ product, setProduct ] = useState<any[]>([])
    const [ pragas, setPragas ] = useState<any[]>([])

    const productName:any = {
        1: 'Rigon Bloco',
        2: 'Rigon IFR',
        3: 'Rigon GS',
        4: 'Bifentol 200SC',
        5: 'Bifentol PS',
        6: 'Formifim'
    }

    const onDel = () => {
        var temp = activity
        temp.pop()
        setActivity([...temp])
    }

    const onAdd = () => {
        setActivity([...activity, 0])
    }

    const onSend = (values:any) => {
        
        setLoadButton(true)

        values.budget_id = budgetId;
        values.service_id = serviceId;
        values.certificate_id = certificateId;

        POST_API(`/execution/save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                reload()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    const onEditar = () => {
        
        setLoadButton(true)

        var values:any = {}

        values.budget_id = budgetId;
        values.service_id = serviceId;
        values.certificate_id = certificateId;

        POST_API(`/execution/back.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                reload()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    const onLoad = () => {
        
        var values:any = {}

        values.budget_id = budgetId;
        values.service_id = serviceId;
        values.certificate_id = certificateId;
        
        POST_API(`/execution/search.php`, { token: getToken(), filter: JSON.stringify(values), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {

                if (res.data) {

                    setActivity(new Array(res.data.activity.length).fill(0))
                    setProduct(res.data.product_id)
    
                    form.setFieldValue('obs', res.data.obs)
                    form.setFieldValue('local', res.data.local)
                    form.setFieldValue('equipament', res.data.equipament)
                    form.setFieldValue('description', res.data.description)
                    form.setFieldValue('activity', res.data.activity)
                    form.setFieldValue('product_id', res.data.product_id)
                    form.setFieldValue('product', res.data.product)

                    setDados(res.data)

                }
                
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoad(false))

    }

    const onLoadPragas = () => {
        POST_API(`/target_pest/search.php`, { token: getToken(), pagination: JSON.stringify({current: 1, page: 99}), sorter: JSON.stringify({selectColumn: 'target_pest.name', order: 'ASC'}), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setPragas(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH)
    }

    useEffect(() => {
        onLoad()
        onLoadPragas()
    }, [])

    if (certificateService.finish === '0' && verifyConfig([CLN])) {
        return (
            <Typography>A planilha de monitoramento ainda não está disponível.</Typography>
        )
    } else if (certificateService.finish === '0') {
        return (
            <Row gutter={[16,16]}>
                { load ? <Col span={24}><LoadComponent /></Col> : (
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form layout="vertical" form={form} onFinish={onSend} style={{width: '100%'}}>
                            <Row gutter={[8,8]}>
                                { activity.map((v,i) => (
                                    <>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item name={['activity', i, 'target_pest_id']} label="Praga alvo">
                                                <Select placeholder="Selecione">
                                                    { pragas.map((praga:any, index:number) => <Select.Option key={index} value={praga.id}>{praga.name}</Select.Option> )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item name={['activity', i, 'activity']} label="Atividade desenvolvida">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )) }
                                <Col span={24}>
                                    <Button onClick={onDel} style={{float: 'right'}} className="btn-default" type="default" disabled={activity.length <= 1}>Deletar</Button>
                                    <Button onClick={onAdd} style={{float: 'right', marginRight: 10}} className="btn-primary" type="primary">Adicionar</Button>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name={'equipament'} label="Equipamento utilizado">
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name={'local'} label="Locais a serem tratados">
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name={'description'} label="Descrição do tratamento">
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Typography>Produtos químicos utilizados</Typography>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name={['product_id']}>
                                        <Checkbox.Group onChange={setProduct}>
                                            <Checkbox value={'1'}>Rigon Bloco</Checkbox>
                                            <Checkbox value={'2'}>Rigon IFR</Checkbox>
                                            <Checkbox value={'3'}>Rigon GS</Checkbox>
                                            <Checkbox value={'4'}>Bifentol 200SC</Checkbox>
                                            <Checkbox value={'5'}>Bifentol PS</Checkbox>
                                            <Checkbox value={'6'}>Formifim</Checkbox>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                                { product.map((value, index) => (
                                    <Col span={24} key={index}>
                                        <Row gutter={[8,0]} align={'bottom'}>
                                            <Form.Item hidden name={['product', index, 'product_id']} initialValue={value}>
                                                <Input />
                                            </Form.Item>
                                            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                                                <Form.Item label="Produto">

                                                    <Input readOnly value={productName[value]} />
                                                </Form.Item>
                                            </Col>
                                            <Col xl={4} lg={4} md={8} sm={8} xs={24}>
                                                <Form.Item name={['product', index, 'lote']} label="Lote">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col xl={4} lg={4} md={8} sm={8} xs={24}>
                                                <Form.Item name={['product', index, 'validate']} label="Validade">
                                                    <Input type="date"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={4} lg={4} md={8} sm={8} xs={24}>
                                                <Form.Item name={['product', index, 'qty']} label="Quantidade">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                )) }
                                <Col span={24}>
                                    <Form.Item name={'obs'} label="Observações">
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Button loading={loadButton} style={{float: 'right'}} className="btn-primary" type="primary" htmlType="submit">Salvar e emitir comprovante de execução</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                )}
            </Row>
        )
    } else if (!load) {
        return (
            <Row gutter={[16,16]}>
                <Col span={24}>
                    <PDFViewer style={{width: '100%', height: 700}}>
                        <Document>
                            <OrdensDeServicoFilesCertificadoComprovanteExecucao values={dados} serviceId={serviceId} data={data} />
                        </Document>
                    </PDFViewer>
                </Col>
                <Col span={24}>
                    <Button loading={loadButton} style={{float: 'right'}} className="btn-primary" type="primary" onClick={onEditar}>Editar informações</Button>
                </Col>
            </Row>
        )
    } else {return <></>}

}

const PlanilhaMonitoramento = ({serviceId, budgetId, certificateId, data, reload} : any) => {

    const [form] = Form.useForm()

    const service = data.services.filter((v:any) => v.id == serviceId)[0];
    const certificate = service.certificates.filter((v:any) => v.certificate_id == certificateId)[0];
    const certificateService = service.need_certificate_info.filter((v:any) => v.certificate_id == certificate.id)[0];

    const [ traps, setTraps ] = useState<any[]>([])
    const [ trapsValue, setTrapsValue ] = useState<any[]>([])
    const [ load, setLoad ] = useState<boolean>(true)
    const [ loadButton, setLoadButton ] = useState<boolean>(false)

    const onSend = (values:any) => {
        
        setLoadButton(true)

        values.budget_id = budgetId;
        values.service_id = serviceId;
        values.certificate_id = certificateId;

        POST_API(`/trap_item/save.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                reload()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    const onEditar = () => {
        
        setLoadButton(true)

        var values:any = {}

        values.budget_id = budgetId;
        values.service_id = serviceId;
        values.certificate_id = certificateId;

        POST_API(`/trap_item/back.php`, { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                reload()
            } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
        }).catch(POST_CATCH).finally( () => setLoadButton(false) )
    }

    const onView = () => {
        POST_API(`/trap/search.php`, { token: getToken(), filter: JSON.stringify({client_id: data.client_id}), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setTraps(res.data);
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH)
    }

    const onLoad = () => {
        
        var values:any = {}

        values.budget_id = budgetId;
        values.service_id = serviceId;
        values.certificate_id = certificateId;

        POST_API(`/trap_item/search.php`, { token: getToken(), filter: JSON.stringify(values), type: 'list' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                form.setFieldValue('traps', res.data)
                form.setFieldValue('obs_planilha', data.obs_planilha)
                setTrapsValue(res.data)
            } else {
                Modal.warning({ title: 'Algo deu errado', content: res.msg })
            }
        }).catch(POST_CATCH).finally( () => setLoad(false))
    }

    useEffect(() => {
        onView()
        onLoad()
    }, [])

    if (certificateService.finish === '0' && verifyConfig([CLN])) {
        return (
            <Typography>A planilha de monitoramento ainda não está disponível.</Typography>
        )
    } else if (certificateService.finish === '0') {
        return (
            <Row gutter={[16,16]}>
                { load ? <Col span={24}><LoadComponent /></Col> : (
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form layout="vertical" form={form} onFinish={onSend} style={{width: '100%'}}>
                            <Form.Item name="traps">
                                <Checkbox.Group style={{width: '100%'}}>
                                    <Row gutter={[16,16]}>
                                        { traps.map((v:any, i:number) => {

                                            var linha = 1;

                                            return (
                                                <Col xl={24} lg={24} md={24} sm={24} xs={24} key={i}>
                                                    <table className="table-panilha">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2+Number(v.situations.length)}><center>{v.name}</center></th>
                                                            </tr>
                                                            <tr className="row-texto-rotacionado">
                                                                <th style={{width: '15%'}} className="col-texto-rotacionado"><center><span className="texto-rotacionado">Armadilhas</span></center></th>
                                                                <th style={{width: '35%'}} className="col-texto-rotacionado"><center><span>Setor</span></center></th>
                                                                { v.situations.map((vs:any, vi:number) => (
                                                                    <th key={vi} style={{width: `${50/Number(v.situations.length)}%`}} className="col-texto-rotacionado"><span className="texto-rotacionado">{vs.name}</span></th>
                                                                )) }
                                                            </tr>
                                                            { v.sectors.map((v2:any, i2: number) => {
                                                                return (
                                                                    <>
                                                                        <tr key={i2}>
                                                                            <td style={{width: '15%'}}><center><span>{v.abbreviation} - {linha++}</span></center></td>
                                                                            <td rowSpan={v2.qty} style={{width: '35%'}}><center><span>{v2.name}</span></center></td>
                                                                            { v.situations.map((vs:any, vi:number) => (
                                                                                <td key={vi} style={{width: `${50/Number(v.situations.length)}%`}}><div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Checkbox value={`${vs.id}-${v2.id}-1`} /></div></td>
                                                                            )) }
                                                                        </tr>
                                                                        { v2.qty > 1 ? new Array(v2.qty-1).fill('-').map((a,b) => (
                                                                            <tr key={i2}>
                                                                                <td style={{width: '15%'}}><center><span>{v.abbreviation} - {linha++}</span></center></td>
                                                                                { v.situations.map((vs:any, vi:number) => (
                                                                                    <td key={vi} style={{width: `${50/Number(v.situations.length)}%`}}><div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Checkbox value={`${vs.id}-${v2.id}-${1+b+1}`} /></div></td>
                                                                                )) }
                                                                            </tr>
                                                                        )) : null }
                                                                    </>
                                                                )
                                                            })}
                                                        </thead>
                                                    </table>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item name="obs_planilha" label="Observações">
                                <Input.TextArea rows={4}/>
                            </Form.Item>
                            <Row>
                                <Col span={24}>
                                    <Button loading={loadButton} style={{float: 'right'}} className="btn-primary" type="primary" htmlType="submit">Salvar e emitir planilha de monitoramento</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                )}
            </Row>
        )
    } else if (!load) {
        return (
            <Row gutter={[16,16]}>
                <Col span={24}>
                    <PDFViewer style={{width: '100%', height: 700}}>
                        <Document>
                            <OrdensDeServicoFilesCertificadoPlanilhaMonitoramento values={trapsValue} traps={traps} serviceId={serviceId} data={data} />
                        </Document>
                    </PDFViewer>
                </Col>
                <Col span={24}>
                    <Button loading={loadButton} style={{float: 'right'}} className="btn-primary" type="primary" onClick={onEditar}>Editar informações</Button>
                </Col>
            </Row>
        )
    } else {return <></>}


}

export default OrdensDeServicoFilesCertificado;