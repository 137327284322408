// react libraries
import { Page, Text, View, Image } from '@react-pdf/renderer';

// services
import { styles } from "../../../../../services";

const OrdensDeServicoFilesCertificadoComprovanteExecucao = ( { data, serviceId, values } : any ) => {

    // Obter a data atual
    const dataAtual = new Date();

    // Obter dia, mês e ano
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();

    // Formatar a data no formato d/m/Y
    const dataFormatada = `${dia}/${mes}/${ano}`;

    const produtos:any = [
        { id: '1', nome: 'Rigon Bloco', concentracao: '0,005%', anvisa: '3.0425.0110.001-8', ativo: 'Brodifacoum', categoria: 'Rodenticida\nanticoagulante', antidoto: 'Tratamento\nSintomático', dosagem: '5g' },
        { id: '2', nome: 'Rigon IFR', concentracao: '0,005%', anvisa: '3.0425.0153.001-2', ativo: 'Brodifacoum', categoria: 'Rodenticida\nanticoagulante', antidoto: 'Tratamento\nSintomático', dosagem: '10g' },
        { id: '3', nome: 'Rigon GS', concentracao: '0,005%', anvisa: '3.0425.0080.001-6', ativo: 'Brodifacoum', categoria: 'Rodenticida\nanticoagulante', antidoto: 'Tratamento\nSintomático', dosagem: '20g' },
        { id: '4', nome: 'Bifentol 200SC', concentracao: '20%', anvisa: '3.2398.0027.001-5', ativo: 'Bifentrina', categoria: 'Piretroide', antidoto: 'Tratamento\nSintomático', dosagem: '30ml/10L água' },
        { id: '5', nome: 'Bifentol PS', concentracao: '0,40%', anvisa: '3.2398.0035.001-9', ativo: 'Bifentrina', categoria: 'Piretroide', antidoto: 'Tratamento\nSintomático', dosagem: '2g' },
        { id: '6', nome: 'Formifim', concentracao: '0,15%', anvisa: '3.2398.0002.001-9', ativo: 'Imidacloprid', categoria: 'Neonicotinoide', antidoto: 'Anti-histáminicos e Tratamento\nSintomático', dosagem: '30g' },
    ]

    return (
        <>
            <Page size="A4" style={styles.page}>
                {/* HEADER */}
                <View style={{...styles.section, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, paddingBottom: 0}}>
                    <Image src="/assets/img/logo.png" style={styles.image} />
                </View>
                <View style={styles.section}>
                    <Text style={{...styles.title, marginBottom: 20}}>Comprovante de Execução</Text>
                    <View style={{...styles.table}}>
                        <View style={styles.row}>
                            <Text style={{...styles.col15, ...styles.cpText, ...styles.borderRight}}>Ordem de serviço: #{data.id}</Text>
                            <Text style={{...styles.col5, ...styles.cpText}}>Data emissão: {dataFormatada}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.tableTh, fontWeight: 700}}>Informações do cliente</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Nome/Razão Social:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_name}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Endereço:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_address}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>{String(data.client_cpf_cnpj).length > 14 ? 'CNPJ' : 'CPF'}</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cpf_cnpj}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>CEP:</Text>
                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cep}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col10, ...styles.cpText, ...styles.borderRight}}>PRAGAS ALVO</Text>
                            <Text style={{...styles.col10, ...styles.cpText }}>ATIVIDADE DESENVOLVIDA</Text>
                        </View>
                        { values?.activity.map((v:any, i:number) => (
                            <View key={i} style={styles.row}>
                                <Text style={{...styles.col10, ...styles.cpText, ...styles.borderRight}}>{v.name}</Text>
                                <Text style={{...styles.col10, ...styles.cpText }}>{v.activity}</Text>
                            </View>
                        )) }
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}></Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>EQUIPAMENTO UTILIZADO</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText }}>{values.equipament}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}></Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>LOCAIS A SEREM TRATADOS</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText }}>{values.local}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}></Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>DESCRIÇÃO DO TRATAMENTO</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText }}>{values.description}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}></Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText, ...styles.textCenter }}>PRODUTOS QUÍMICOS</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col1, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}></Text>
                            <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>PRODUTO</Text>
                            <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>CONCENTRAÇÃO</Text>
                            <Text style={{...styles.col3, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>REGISTRO ANVISA</Text>
                            <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>PRINCÍPIO ATIVO</Text>
                            <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>CATEGORIA</Text>
                            <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>ANTÍDOTO</Text>
                            <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>DOSAGEM</Text>
                            <Text style={{...styles.col1, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>LOTE</Text>
                            <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>VALIDADE</Text>
                            <Text style={{...styles.col3, ...styles.cpTextMinus, ...styles.textCenter }}>QUANTIDADE UTILIZADA</Text>
                        </View>
                        { produtos.map((v:any, i:number) => (
                            <View style={styles.row} key={i}>
                                <Text style={{...styles.col1, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight, paddingTop: 6.5 }}>( { values.product_id.includes(v.id) ? 'X' : '' } )</Text>
                                <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight, paddingTop: 6.5 }}>{v.nome}</Text>
                                <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight, paddingTop: 6.5 }}>{v.concentracao}</Text>
                                <Text style={{...styles.col3, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight, paddingTop: 6.5 }}>{v.anvisa}</Text>
                                <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight, paddingTop: 6.5 }}>{v.ativo}</Text>
                                <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>{v.categoria}</Text>
                                <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight }}>{v.antidoto}</Text>
                                <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight, paddingTop: 6.5 }}>{v.dosagem}</Text>
                                <Text style={{...styles.col1, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight, paddingTop: 6.5 }}>{values.product.filter((item:any) => item.product_id === v.id)[0]?.lote}</Text>
                                <Text style={{...styles.col2, ...styles.cpTextMinus, ...styles.textCenter, ...styles.borderRight, paddingTop: 6.5 }}>{values.product.filter((item:any) => item.product_id === v.id)[0]?.validate_format}</Text>
                                <Text style={{...styles.col3, ...styles.cpTextMinus, ...styles.textCenter, paddingTop: 6.5 }}>{values.product.filter((item:any) => item.product_id === v.id)[0]?.qty}</Text>
                            </View>
                        )) }
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}></Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText, ...styles.textCenter}}>OBSERVAÇÕES</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={{...styles.col20, ...styles.cpText}}>{values.obs}</Text>
                        </View>
                    </View>
                    
                    <View style={{...styles.cpSignature}}>
                        <Text style={{...styles.textCenter, fontSize: 10}}>NLG SOLUTIONS</Text>
                        <Text style={{...styles.textCenter, fontSize: 10}}>CNPJ: 24.534.437/0001-73</Text>
                    </View>
                </View>
            </Page>
        </>
    )

}

export default OrdensDeServicoFilesCertificadoComprovanteExecucao;