// react libraries
import { Col, Popover, Row, Switch, Tag, Typography } from "antd";
import { useEffect, useState } from "react";

// services
import { ADM, CLN, DEV, GRN, OPR, PageDefaultProps, verifyConfig } from "../../../services";

// components
import TableComponent from "../../../components/TableComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableTrEyeButton, TableTrCardButton, TableTrStartButton, TableTrFinishButton, TableTrVisitButton, TableTrDocumentButton } from "../../../components/TableComponent/buttons";

const OrdensDeServico = ( { type, path, permission } : PageDefaultProps ) => {

    // states
    const [ action, setAction ] = useState(false);
    const [ finish, setFinish ] = useState(false);

    useEffect(() => {
        setAction(!action)
    }, [finish])

    // table columns
    const column = [
        { title: 'Código', dataIndex: 'id', table: 'budget.id', width: '100px', sorter: true, align: 'center', render: null },
        { title: 'Previsão Execução', dataIndex: 'scheduled_execution_date_format', table: 'budget.scheduled_execution_date', width: '160px', sorter: true, align: 'center', render: null },
        { title: 'Execução', dataIndex: 'started_execution_at_format', table: 'budget.started_execution_at', width: '160px', sorter: true, align: 'center', render: (item:any) => (
            <Row align={'middle'} style={{width: '100%', flexDirection: 'column'}}>
                <Popover title="Execução iniciada" content={`Por ${item.started_name}`}><Typography style={{color: '#ff9807', fontWeight: 600}}>{item.started_execution_at ? item.started_execution_at_format : null}</Typography></Popover>
                <Popover title="Execução finalizada" content={`Por ${item.finished_name}`}><Typography style={{color: '#a52834', fontWeight: 600}}>{item.finished_execution_at ? item.finished_execution_at_format : null}</Typography></Popover>
            </Row>
        ) },
        { title: 'Cliente', dataIndex: 'client_name', table: 'client.name', width: 'auto', minWidth: '200px', sorter: true, align: 'left', render: null },
        { title: 'Valor', dataIndex: 'total_name', table: '( budget.price - budget.discount )', width: '140px', sorter: true, align: 'right', render: null, hide: verifyConfig([OPR]) },
        { title: 'Status', dataIndex: 'status', width: '180px', sorter: false, align: 'center', render: (item:any) => ( <Row justify={'center'} style={{width: '100%'}}><Tag style={{margin: 0}} color={item.status_execution_color}>{item.status_execution_name}</Tag></Row> ) },
        { title: 'Ações', dataIndex: null, width: '100px', sorter: false, align: 'center', render: (item: any) => (
            <Row justify={'center'} style={{width: '100%'}}>
                <TableTrEyeButton type={type} permission={[ADM,GRN,OPR,CLN]} item={item} />
                { item.status_execution_name === 'Aguardando execução' ? <TableTrCardButton type={type} permission={[DEV,ADM,GRN]} item={item} /> : null }
                { item.status_execution_name === 'Aguardando visita técnica' ? <TableTrVisitButton type={type} permission={[OPR]} item={item} /> : null }
                { item.status_execution_name === 'Aguardando execução' ? <TableTrStartButton type={type} permission={[OPR]} item={item} action={() => setAction(!action)} path={path} /> : null }
                { item.status_execution_name === 'Em execução' ? <TableTrFinishButton type={type} permission={[OPR, ADM]} item={item} /> : null }
                { item.status_execution_name === 'Finalizado' ? <TableTrDocumentButton type={type} permission={[DEV,ADM,GRN,CLN]} item={item} /> : null }
            </Row>
        ) },
    ]

    return (
        <PageDefaultComponent title="Gerenciamento de Ordens de Serviço" items={[
            { title: type === 'list' ? 'Lista' : 'Lixeira' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    <CardAdminComponent title="Ordens de Serviço" subtitle={type === 'list' ? 'Lista' : 'Lixeira'} options={
                        <Row justify={'end'} gutter={[8,8]}>
                        </Row>
                    }>
                        <Switch onChange={setFinish} style={{position: 'absolute', top: -32, right: 0}} checkedChildren="Mostrar finalizados" unCheckedChildren="Esconder finalizados" />
                        <TableComponent 
                            column={column}
                            path={path}
                            type={type}
                            action={action}
                            defaultFilter={{ not_finish: !finish, status: 'approved', technical: true }}
                            initOrder="DESC"
                        />
                    </CardAdminComponent>
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default OrdensDeServico;