// react libraries
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Form, Modal, Row } from "antd";

// services
import { POST_API, POST_CATCH, PageDefaultProps, getToken, styles } from "../../../services";

// components
import LoadItemComponent from "../../../components/LoadItemComponent";
import CardAdminComponent from "../../../components/CardAdminComponent";
import PageDefaultComponent from "../../../components/PageDefaultComponent";

// buttons components
import { TableReturnButton } from "../../../components/TableComponent/buttons";

// icons
import { Document, PDFViewer, Page, View, Image, Text } from "@react-pdf/renderer";

const OrcamentosPDF = ( { type, path, permission } : PageDefaultProps ) => {
    
    // params 
    const { ID } = useParams()

    // state
    const [ visit, setVisit ] = useState(false)
    const [ pest, setPest ] = useState(false)
    const [ load, setLoad ] = useState(true)
    const [ data, setData ] = useState<any>(null)
    const [ total, setTotal ] = useState<any>(null)
    const [ prestadoresSelected, setPrestadoresSelected ] = useState<any[]>([])
    const [ servicesSelected, setServicesSelected ] = useState<any[]>([])

    const [ pragasSelected, setPragasSelected ] = useState<any[]>([])
    
    // form
    const [ form ] = Form.useForm()

    // valid new or edit
    useEffect(() => {
        if (type === 'add') { 
            form.setFieldValue('price', 0)
            form.setFieldValue('discount', 0)
            setLoad(false)
        } else {
            setLoad(true)
            POST_API(`/${path}/search.php`, { token: getToken(), filter: JSON.stringify({ id: ID }), type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    setData(res.data)
                    setServicesSelected(res.data.services)
                    setPrestadoresSelected(res.data.tecnica)
                    setPragasSelected(res.data.pragas)
                } else { Modal.warning({ title: 'Algo deu errado', content: res.msg }) }
            }).catch(POST_CATCH).finally( () => setLoad(false))
        }
    }, [type, path, form, ID]);

    const validVisit = () => {
        var visit = servicesSelected.filter((v) => v.has_technical_visit === '1')
        setVisit(visit.length > 0)
    }

    const validPest = () => {
        var pest = servicesSelected.filter((v) => v.has_target_pest === '1')
        setPest(pest.length > 0)
    }

    const loadValue = () => {
        setTotal(servicesSelected.reduce((sum, item) => Number(sum) + Number(item.price), 0))
        form.setFieldValue('price', servicesSelected.reduce((sum, item) => Number(sum) + Number(item.price), 0))
    }

    useEffect(() => {
        form.setFieldValue('total', form.getFieldValue('price')-form.getFieldValue('discount'))
    }, [total])

    useEffect(() => {
        validVisit()
        validPest()
        loadValue()
        form.setFieldValue('services', servicesSelected)
    }, [servicesSelected])

    // Obter a data atual
    const dataAtual = new Date();

    // Obter dia, mês e ano
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
    const ano = dataAtual.getFullYear();

    // Formatar a data no formato d/m/Y
    const dataFormatada = `${dia}/${mes}/${ano}`;

    return (
        <PageDefaultComponent title="Gerenciamento de Orçamentos" items={[
            { title: 'PDF' },
        ]}>
            <Row gutter={[20,20]}>
                <Col md={24} xs={24}>
                    { load ? <LoadItemComponent /> :
                    <Row gutter={[8,8]}>
                        <Col md={24} xs={24}>
                            <CardAdminComponent title="Orçamentos" subtitle={'PDF'} options={
                                <Row justify={'end'} gutter={[8,8]}>
                                    <TableReturnButton type={type} permission={permission} />
                                </Row>
                            }>
                                <Col span={24}>
                                    <PDFViewer style={{width: '100%', height: 700}}>
                                        <Document>
                                            <Page size="A4" style={styles.page}>
                                                {/* HEADER */}
                                                <View style={{...styles.section, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, paddingBottom: 0}}>
                                                    <Image src="/assets/img/logo.png" style={styles.image} />
                                                </View>
                                                <View style={styles.section}>
                                                    <Text style={{...styles.title, marginBottom: 20}}>Orçamento</Text>
                                                    <View style={{...styles.table}}>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col15, ...styles.cpText, ...styles.borderRight}}>Ordem de serviço: #{data.id}</Text>
                                                            <Text style={{...styles.col5, ...styles.cpText}}>Data emissão: {dataFormatada}</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col20, ...styles.tableTh, fontWeight: 700}}>Informações do cliente</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Nome/Razão Social:</Text>
                                                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_name}</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Endereço:</Text>
                                                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_address}</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>{String(data.client_cpf_cnpj).length > 14 ? 'CNPJ' : 'CPF'}</Text>
                                                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cpf_cnpj}</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>CEP:</Text>
                                                            <Text style={{...styles.col15, ...styles.cpText}}>{data.client_cep}</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col20, ...styles.cpText, ...styles.borderRight}}></Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col20, ...styles.tableTh, fontStyle: 'bold'}}>SERVIÇOS</Text>
                                                        </View>
                                                        { servicesSelected.map((v, i) => (
                                                            <>
                                                                <View style={styles.row}>
                                                                    <Text style={{...styles.col2, ...styles.cpText, ...styles.borderRight}}>Nome:</Text>
                                                                    <Text style={{...styles.col13, ...styles.cpText, ...styles.borderRight}}>{v.name}</Text>
                                                                    <Text style={{...styles.col2, ...styles.cpText, ...styles.borderRight}}>Valor:</Text>
                                                                    <Text style={{...styles.col3, ...styles.cpText, ...styles.textCenter}}>R$ {Number(v.price).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</Text>
                                                                </View>
                                                                <View style={styles.row}>
                                                                    <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Visita técnica:</Text>
                                                                    <Text style={{...styles.col3, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}>{v.need_technical_visit == '1' && v.has_technical_visit == '1' ? 'Sim' : 'Não'}</Text>
                                                                    <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Relatório:</Text>
                                                                    <Text style={{...styles.col2, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}>{v.need_report == '1' && v.has_report == '1' ? 'Sim' : 'Não'}</Text>
                                                                    <Text style={{...styles.col4, ...styles.cpText, ...styles.borderRight}}>Certificado(s):</Text>
                                                                    <Text style={{...styles.col3, ...styles.cpText, ...styles.textCenter}}>{v.need_certificate && v.has_certificate == '1' ? 'Sim' : 'Não'}</Text>
                                                                </View>
                                                            </>
                                                        )) }
                                                        { pest ? (
                                                            <>
                                                                <View style={styles.row}>
                                                                    <Text style={{...styles.col20, ...styles.cpText }}></Text>
                                                                </View>
                                                                <View style={styles.row}>
                                                                    <Text style={{...styles.col20, ...styles.tableTh, fontStyle: 'bold'}}>PRAGAS ALVO</Text>
                                                                </View>
                                                                { pragasSelected.map((v,i) => (
                                                                    <View style={styles.row}>
                                                                        <Text style={{...styles.col20, ...styles.cpText}}>{v.name}</Text>
                                                                    </View>
                                                                )) }
                                                            </>
                                                        ) : null }
                                                        { visit ? (
                                                            <>
                                                                <View style={styles.row}>
                                                                    <Text style={{...styles.col20, ...styles.cpText }}></Text>
                                                                </View>
                                                                <View style={styles.row}>
                                                                    <Text style={{...styles.col20, ...styles.tableTh, fontStyle: 'bold'}}>VISITA TÉCNICA</Text>
                                                                </View>
                                                                { prestadoresSelected.map((v,i) => (
                                                                    <View style={styles.row}>
                                                                        <Text style={{...styles.col20, ...styles.cpText}}>{v.name}</Text>
                                                                    </View>
                                                                )) }
                                                            </>
                                                        ) : null }
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col20, ...styles.cpText }}></Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col20, ...styles.tableTh, fontStyle: 'bold'}}>TOTAIS</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight}}>Data prevista da execução:</Text>
                                                            <Text style={{...styles.col5, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}>{data.scheduled_execution_date_format}</Text>
                                                            <Text style={{...styles.col2, ...styles.cpText, ...styles.borderRight}}>Desconto:</Text>
                                                            <Text style={{...styles.col3, ...styles.cpText, ...styles.borderRight, ...styles.textCenter}}>R$ {Number(data.discount).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</Text>
                                                            <Text style={{...styles.col2, ...styles.cpText, ...styles.borderRight}}>Total:</Text>
                                                            <Text style={{...styles.col3, ...styles.cpText, ...styles.textCenter}}>R$ {Number(data.total).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col20, ...styles.cpText }}></Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col20, ...styles.tableTh, fontStyle: 'bold'}}>OBSERVAÇÕES</Text>
                                                        </View>
                                                        <View style={styles.row}>
                                                            <Text style={{...styles.col20, ...styles.cpText}}>{data.description}</Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </Page>
                                        </Document>
                                    </PDFViewer>
                                </Col>
                            </CardAdminComponent>
                        </Col>
                    </Row>
                    }
                </Col>
            </Row>
        </PageDefaultComponent>
    )

}

export default OrcamentosPDF;